.SelectCity-container{
  .ui-autocomplete{
    max-height:400px;
    overflow-y: auto;
    overflow-x: hidden;
    font-family: 'Open Sans', serif;
    .ui-menu-item{
      padding:10px 1em 10px .4em;
    }
    .ui-state-focus{
      color:black;
      outline:none;
      border:none;
      font-family: 'Open Sans Semibold', serif;
      background: #80CAC3;
    }

  }
}