.CheckGroup-container{
/*  column-count: <?= $options["columnsCount"] ?? "unset"?>;*/
  height:99%;
  @media (max-width:767px) {
    column-count:unset;
  }
  .check-group-div .custom-answer{
    display: block!important;
  }
}
