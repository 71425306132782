.radio-group-matrix-div {
    .custom-input{
        display: block;
        width: 100%;
        color: #000;
        padding: 0 3px;
        border: 0;
        background-color: transparent!important;
        text-align: center;
        &::placeholder {
            color: rgba(0,0,0,.6);
            opacity: 1;
        }
    }
    .stub{
        position: absolute;
        top: 0;
        left: 0;
        background-color: #fffcfb;
        width: 100px;
        z-index: 101;
        border-bottom: 2px solid rgba(50, 0, 0, 0.25);
        border-right: 2px solid rgba(50, 0, 0, 0.25);
    }
    th, td{
        min-width: 130px;
    }
    .floating-column-headers{
        display: flex;
        position: absolute;
        align-items: stretch;
        top: 0;
        z-index: 10;
        left: 0;
        margin-bottom: 0;
        background: #fffcfb;
        &.transposed{
            align-items:stretch;
            min-height: 35px;
            th, td{
                min-width: 130px;
            }
            .header{
                min-height: 35px;
                border: 1px solid rgba(50, 0, 0, .25);
                border-top: none;
                border-bottom: 0;
                &:nth-child(even){
                    background: rgba(0,0,0,.035);
                }
                &.img{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-items: center;
                    overflow: hidden;
                    min-height: 100%;
                    img{
                        max-width: 80px;
                        max-height: 80px;
                        display: inline-block;
                        vertical-align: middle;
                    }
                    p{
                        min-width: 100%;
                        align-self: flex-end;
                        padding-bottom: 15px;
                    }
                }
            }
        }
        &.vertical-header{
            display: block;
            border-right: 2px solid rgba(50, 0, 0, .25);
            .header{
                background-color: rgb(255, 252, 251);
                &:nth-child(even){
                    background: rgb(255, 252, 251);
                }
                &:last-child{
                    border-bottom-width: 2px;
                }
                border: 0;
                border-top: 1px solid rgba(50, 0, 0, .25);
                border-bottom: 1px solid rgba(50, 0, 0, .25);
            }
            .empty-header{
                border: 0;
                left: 0;
                top: 0;
                & + .header{
                    border-top-width: 2px;
                }
            }
        }
        &.horizontal-header{
            border-bottom: 2px solid rgba(50, 0, 0, .25);
        }
        .header, .empty-header{
            border-bottom: 0;
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
        }
        .header,.empty-header{
            width: 100px;
            text-align: center;
            padding:0 5px;
        }
        .header{
            border: 1px solid rgba(50, 0, 0, .25);
            border-top:none;
            border-bottom: 0;
            &:last-child{
                border-right:none;
            }
        }
        .empty-header{
            border:2px solid transparent;
            border-left:none;
            border-right:1px solid rgba(50, 0, 0, .25);
        }
        &.fixed {
            position: fixed;
            z-index: 999;
            width: 100%;
            max-width: 1200px;
            top: 0;
        }
    }
    .radio-group-matrix{
        min-width: 100%;
        label{
            width: 100%;
        }
        input:not(.custom-input){
            display: none;
        }
        th{
            min-height: 55px;
            width: 100px;
            text-align: center;
            padding: 5px;
        }
        td, th{
            border: 1px solid rgba(50, 0, 0, 0.25);
            &:first-child{
                border-left: none;
            }
            &:last-child{
                border-right: none;
            }
        }
        &.transposed{
            .radio-button,.check-button{
                &:not(.disabled):nth-child(even){
                    background: rgba(0,0,0,0.035);
                }
            }
            th{
                padding-top:10px;
                padding-bottom:10px;
            }
            td,th{
                border:1px solid rgba(50, 0, 0, 0.25);
            }
            td:first-child,th:first-child{
                border-left:none;
            }
            td:last-child,th:last-child{
                border-right:none;
            }
            .item-row{
                .item-text{
                    padding-right: 5px;
                }
                &:nth-child(even){
                    background:initial;
                }
                &:last-child{
                    td, th{
                        border-bottom-width: 2px;
                    }
                }
            }
        }
        .item-row{
            height:100px;
            &:nth-child(even){
                background: rgba(0, 0, 0, .035);
            }
        }
        .item-text{
            overflow-x: auto;
            padding:10px 0 10px 10px;
            vertical-align: middle;
            text-align: center;
            &.with-img{
                overflow: hidden;
                .img{
                    max-height: 80px;
                    max-width: 80px;
                    display: inline-block;
                }
                p{
                    text-align: center;
                }
            }
        }
        .radio-button,.check-button{
            text-align: center;
            vertical-align: middle;
        }
        .custom-button{
            transition: none;
            &.disabled{
                background-color: #e5e5e5;
                pointer-events: none;
            }
        }
        .check-button.disabled{
            pointer-events: none;
            background-color: #e5e5e5;
        }
        .radio-matrix-check-button{
            background: url("/images/new_respo/quiz/questions/check11-text.png") no-repeat 50% 50%;
            overflow: hidden;
            transition: background .2s ease-out 0s, color .2s ease-out 0s, opacity .5s ease-out 0s, filter .2s linear;
            width: 100%;
            height: 70px;
            &:not(.active):hover{
                background: url("/images/new_respo/quiz/questions/check11-text.png") no-repeat 50% 50%, rgba(0,0,0,0.08) ;
                cursor: pointer;
            }
            &.active:hover{
                background: url("/images/new_respo/quiz/questions/check1-text-active.png") no-repeat 50% 50%, rgba(0,0,0,0.08) ;
                cursor: pointer;
            }
            &.active{
                background-image: url("/images/new_respo/quiz/questions/check1-text-active.png");
                filter: saturate(230%) contrast(195%) brightness(65%);
            }
        }
        .radio-button{
            .radio-matrix-button-wrapper{
                transition: background 0.2s;
                width: 100%;
                height: 70px;
                display: flex;
                align-items: center;
                &:hover .radio-matrix-button{
                    border: 2px solid rgb(122, 102, 82);
                    cursor: pointer;
                }
                &:hover{
                    cursor: pointer;
                    background-color: rgba(0, 0, 0, .08);
                }
                .radio-matrix-button{
                    margin: 0 auto;
                    width: 25px;
                    position: relative;
                    height: 25px;
                    display: block;
                    border:2px solid rgba(122, 102, 82, .5);
                    border-radius: 50%;
                    z-index: 5;
                    transition: border linear .2s 0s, filter .2s linear;
                    .inside-circle{
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        border-radius: 50%;
                        display: none;
                        margin-left: -7.5px;
                        margin-top: -7.5px;
                        width: 15px;
                        height: 15px;
                        @media screen and (max-width: 600px){
                            width: 10px;
                            height: 10px;
                            margin-left: -5px;
                            margin-top: -5px;
                        }
                    }
                    &.active{
                        border: 2px solid rgba(122, 102, 82, 1);
                    }
                    &.active > .inside-circle{
                        display: block;
                        transition: background linear 0.2s 0s;
                        background: rgb(122, 102, 82);
                        opacity: .8;
                    }
                    &:not(.active)> .inside-circle{
                        display: none;
                    }
                    @media screen and (max-width: 600px){
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }
    }
}