.CheckGroupImaged-container{
	input[type='text']{
		display: block;
	}
	.buttons {
		margin: 0;
	}
	.custom-div{
		width: 100% !important;
		height: 100%;
	}
	.label-custom{
		padding: 0 10px;
		width: 100%;
		margin: 5px;
	}
	.images-container{
		//justify-content: space-between;

	}
	.images-container .buttons {
		max-width: 45%;
		width: 45%!important;
		&.col-sm-12 {
			width: 100% !important;
			max-width: 100%;
		}
		&.col-sm-11 {
			width: 91.66666667% !important;
			max-width: 100%;
		}
		&.col-sm-10 {
			width: 83.33333333% !important;
			max-width: 100%;
		}
		&.col-sm-9 {
			width: 75% !important;
			max-width: 100%;
		}
		&.col-sm-8 {
			width: 66.66666667% !important;
			max-width: 100%;
		}
		&.col-sm-7 {
			width: 58.33333333% !important;
			max-width: 100%;
		}
		&.col-sm-6 {
			width: 50% !important;
			max-width: 100%;
		}
		&.col-sm-5 {
			width: 41.66666667% !important;
		}
		&.col-sm-4 {
			width: 33.33333333% !important;
		}
		&.col-sm-3 {
			width: 25% !important;
		}
		&.col-sm-2 {
			width: 16.66666667% !important;
		}
		&.col-sm-1 {
			width: 8.33333333% !important;
		}

	}
	.buttons-custom .form-test-checkbox-input{
		margin-right: 0;
		.test-checkbox {
			.radioButton{
				padding-left: 0;
			}
			.form-test-checkbox{
				padding: 0;
				div{
					margin-left: 0;
					margin-right: 0;
					padding-left: 0;
					padding-right: 0;
				}
			}
		}
	}
	input[type="text"]{
		background-color: transparent;
		font-family: "Open Sans", sans-serif;
		line-height: inherit;
		font-size: 12px;
		&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
			color: #7f7e7d;
		}
		&::-moz-placeholder { /* Firefox 19+ */
			color: #7f7e7d;
		}
		&:-ms-input-placeholder { /* IE 10+ */
			color: #7f7e7d;
		}
		&:-moz-placeholder { /* Firefox 18- */
			color: #7f7e7d;
		}
	}
	.label-custom{
		margin: 0;
		padding: 0;
	}
	.item.form-test-checkbox-input.disabled{
		opacity: .4;
	}
}

body .site-content .form-answers .content-question-main-images .images-container.big-images .buttons div:first-child label{
	height: auto;
}
body .site-content .form-answers .buttons-custom input[type='text']{
	width: 100%!important;
}
.images-container .buttons label{
	display: block;
}
body .site-content .form-answers .content-question-main-images .buttons .text-image{
	margin: 0;
}
body .site-content .form-answers .content-question-main-images .buttons .text-image:hover{
	border: 1px solid #ef5451;
}
body .site-content .form-answers .content-question-main-images .buttons.no-checked .text-image:hover{
	border-width: 1px;
}
body .site-content .form-answers .content-question-main-images .buttons .text-image .hover{
	border: 1px solid transparent;
}
body .site-content .form-answers .content-question-main-images .buttons .text-image:hover .hover{
	border-color: #ef5451;
}
body .site-content .form-answers .content-question-main-images .buttons .text-image:hover .icon{
	border-style: solid;
	border-color: #ef5451;
	border-width: 2px 1px 1px 2px;
}
body .site-content .form-answers .content-question-main-images .buttons > div:first-child{
	width: auto;
}

body .site-content .form-answers .content-question-main-images .buttons .text-image .img{
	background-color: transparent;
	max-height: 200px;
}
body .site-content .form-answers  .form-test-checkbox-input .test-checkbox-input .test-custom-img,
body .site-content .form-answers .test-custom-img .form-test-checkbox-input .test-checkbox-input:hover .test-custom-img{
	background: none !important;
	padding-left: 0;
}

body .site-content .form-answers .content-question-main-images .buttons.checked .text-image{
	border: 1px solid #ef5451;
}
body .site-content .form-answers .content-question-main-images .buttons .text-image:hover .icon{
	background-position: -1px -1px;
}


.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
	position: relative;
	min-height: 1px;
	padding-left: 15px;
	padding-right: 15px;
	margin:0px !important;
}

@media (min-width: 768px) {
	.col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
		float: left;
	}

	.col-sm-12 {
		width: 100% !important;
	}

	.col-sm-11 {
		width: 91.66666667% !important;
	}

	.col-sm-10 {
		width: 83.33333333% !important;
	}

	.col-sm-9 {
		width: 75% !important;
	}

	.col-sm-8 {
		width: 66.66666667% !important;
	}

	.col-sm-7 {
		width: 58.33333333% !important;
	}

	.col-sm-6 {
		width: 50% !important;
	}

	.col-sm-5 {
		width: 41.66666667% !important;
	}

	.col-sm-4 {
		width: 33.33333333% !important;
	}

	.col-sm-3 {
		width: 25% !important;
	}

	.col-sm-2 {
		width: 16.66666667% !important;
	}

	.col-sm-1 {
		width: 8.33333333% !important;
	}
}