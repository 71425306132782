.RadioGroup-container{
    .test-checkbox-input{
        transition:background .2s;
        &:hover{
            background: rgba(0, 0, 0, .08);
        }
    }
    [type="radio"]:checked + .test-checkbox.radioButton .radio-btn .radio-btn-inside{
        background-color: #80CAC3 !important;
    }
    @media (max-width:767px) {
        column-count:unset!important;
    }
}