body {
  background-color: rgb(234, 234, 234);

  @media (max-width: 767px), (max-height: 767px) {
    overflow: visible;
  }

  @media (min-width: 768px),(min-height: 768px) {
    height: 100%;
  }
}

body .site-content {
  background: none;

  position: relative;
  min-width: 454px;

  @media (min-width: 768px) {
    overflow: hidden;
    height: 100%;
    width: 100%;
  }
}

html {
  padding: 0;

  @media (min-width: 768px),(min-height: 768px) {
    height: 100%;
  }

}

.new-test:not(.tester) {
  height: 100%;
  position: relative;

  @media (min-width: 768px) {
    padding: 20px 0;
  }
  &.iframe{
    min-height: 450px;
    @media screen and (max-height: 750px) {
      padding-top: 89px;
    }
    .cover .main-test-block {
      transition-property: none;
      box-shadow: none;
      .session-delay-btn {
        display: none;
      }
    }
  }

}

.btn-next-block {
  width: 100%;
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.btn-next-block-small {
  margin: auto;

  .button-next, .button-stat {
    border-radius: 3px;
    margin: 5px;
    display: inline-block;
    vertical-align: top;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;

    .loading-circle {
      margin-top: 10px;
    }

    &:hover {
      opacity: 0.85;
    }

    .show-stat, .btn-next {
      cursor: pointer;
      display: block;

      .img {
        width: 45px;
        height: 45px;
        border-radius: 5px;
      }

    }
  }
  .button-next {
    position: relative;

    .btn-next {

      .img {
        background: url("/images/quiz-buttons/next.png") no-repeat 100%;
        background-size: 45px;
      }

    }
  }

  .button-stat {
    background-color: #4f4756;
    position: relative;

    .show-stat {

      .img {
        background: url("/images/quiz-buttons/stat.png") no-repeat 100%;
        background-size: 45px;
      }

    }
  }

}

.btn-next-block-big {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  margin: auto;
  align-items: flex-end;

  @media ( max-width: 1200px) {
    margin: 0;
    justify-content: center;
  }
  @media ( max-width: 767px) {
    margin-right: 30px;
    justify-content: center;
  }
}

.big {
  .btn-next, .show-stat {
    div {
      line-height: 29px;
      height: 100%;
    }
  }
}

.button-next.big, .button-stat.big {
  height: 40px;
  width: 145px;
  min-width: 145px;
  font-size: 11px;
  text-align: center;
  line-height: 25px;
  display: inline-block;
  letter-spacing: 0.5px;
  position: relative;
  font-family: 'Open Sans Semibold', serif;
  margin: 0 5px;

  .popup-quiz {
    left: -59px;
  }

  a {
    text-decoration: none !important;
    color: #302E32;
  }

  &:hover {

    &.button-next.big {
      background-color: #80CAC3;
      border-color: transparent;
    }

    &.button-stat.big {
      background-color: #E6E7E8;
    }

  }
}

.button-next.big {

  border: 4px solid #80CAC3;
}

.button-stat.big {
  transition: border 0.1s ease-in-out;
  border: 4px solid #E6E7E8;
  &.animate{
    animation: animateStat 1s 1 ease-in-out alternate;
  }

}

@keyframes animateStat{
  0% {
    border: 4px solid #E6E7E8;
    transform: translateY(0);
  }
  25% {
    transform: translateY(-10px);
  }
  50%{
    border: 4px solid #80CAC3;
  }
  75%{
    transform: translateY(10px);
  }
  100% {
    border: 4px solid #E6E7E8;
    transform: translateY(0);
  }

}

.for-research-policy {
  //position: absolute;
  width: 25%;
  //right: 20px;
  text-align: left;
  font-size: 11px;
  font-family: 'Open Sans Semibold', serif;
  //top: 0;
  //min-width:170px;
  //height: 50px;
  margin-top: -12px;
  margin-right: -17px;

  @media (max-width: 1200px) {

    line-height: 13px;

  }
  @media (max-width: 767px) {
    //  top:135%;
    //  left:0;
    //  text-align:center;
    //  width:100%;
    //
    text-align: center;
    min-width: 100%;
    padding-top: 20px;
    margin-top: -10px;
  }

  @media (max-width: 635px) {
    //top:175%;
  }

  @media (max-width: 591px) {
    //right: 0;
    //line-height: 11px;
    //padding: 9px;
    //padding-top: 0px;
  }

  @media (max-width: 520px) {
    line-height: 11px;
  }

}

.session-delay-help-container {
  //position: absolute;
  //bottom: 12px;
  //left: 0;
  width: 176px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  @media ( max-width: 1200px ) {
    margin-right: -20px;
  }
  @media ( max-width: 545px ) {
    width: auto;
    //margin-right:-60px;
  }
  @media ( max-width: 767px ) {
    width: auto;
  }
}

.question-help {
  position: relative;
  margin-right: 10px;
  .button {
    position: relative;
    width: 24px;
    height: 24px;
    cursor: pointer;
    border-radius: 3px;
    background-size: contain;
    background: url("/images/new_respo/quiz/questions/help_ico.png") no-repeat center, #EE2B4E;
    &:hover {
      background: url("/images/new_respo/quiz/questions/help_ico.png") no-repeat center, rgba(238, 43, 78, .75);
    }

    &::after {
      content: "";
      display: block;
    }
    &--tooltip:hover::after {
      border-color: transparent transparent rgba(238, 43, 78, .75) transparent;
    }
    &--tooltip::after {
      content: " ";
      display: block;
      visibility: visible;
      position: absolute;
      bottom: 100%;
      left: 50%;
      margin-left: -10px;
      border-width: 10px;
      border-style: solid;
      border-color: transparent transparent #EE2B4E transparent;
    }

  }
  .hint {
    box-shadow: 1px 1px 1px rgba(0, 0, 0, .2);
    display: none;
    background-color: #FF8275;
    color: #fff;
    min-width: 200px;
    padding: 10px;
    border-radius: 2px;
    bottom: 100%;
    margin-bottom: 10px;
    left: 0;
    position: absolute;
    z-index: 1000;
    &--visible {
      display: block;
    }
    .header {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
      padding: 0 5px 5px;
      margin-bottom: 3%;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      .text {
        font-size: 0.85rem;
        font-weight: 700;
      }
      .close--hint {
        width: 10px;
        height: 10px;
        cursor: pointer;
        background: url("/images/new_respo/quiz/questions/close_hint.png") no-repeat center;
      }
    }
    .hint--text {
      padding: 5px 5px 0;
      font-size: 12px;
      text-align: left;
      line-height: 19px;
    }
  }
}

.show-stat {
  z-index: 100;
  position: relative;
}

.cover {
  @media (min-width: 768px),(min-height: 768px) {
    height: 100%;
  }

  position: relative;

  @media (max-width: 767px) {
    overflow: visible;


  }

  .main-test-block {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    padding: 40px 50px 56px 50px;


    background: rgba(255, 252, 251, 0.93);
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.25);

    display: none;

    @media (min-width: 768px),(min-height: 768px) {
      transition: height 0.7s ease-in 0s, top 0.7s ease-in 0s, left 0.7s cubic-bezier(0.55, 0.06, 0.68, 0.19) 0s;
      height: 100%;
      width: 100%;
      display: block;
    }

    @media (max-width: 767px) {
      padding: 32px 15px 15px 15px !important;
      &:last-child {
        display: block;
        position: relative;

      }
      .btn-next-block {
        bottom: 5px;
        position: relative;
        height: auto;
        width: auto;
        margin: 0;
        background-color: transparent;
        &:before {
          display: none;
        }
      }
    }

    @media screen and (max-width: 545px) {
      .btn-next-block-big {
        display: none;
      }
    }

    @media screen and (min-width: 545px) {
      .btn-next-block-small {
        display: none;
      }
    }

    .titles {
      position: relative;
      z-index: 100;
    }
  }
}


@import "_imports";