.bulkInputText-container{
  .header-text {
    font: normal 18px "Open Sans", serif;
    letter-spacing: 1px;
    color: #000;
    text-transform: none;
    max-width: 722px;
    padding: 0 30px 10px 0;
    margin: 0 0 20px;
  }
  .bulk-text-column {
    display: inline-block;
    margin: 10px;
    & > p {
      color: rgba(0,0,0,.75);
      font: 14px "Open Sans";
      text-transform: none;
      margin-bottom: 10px;
    }
    & > textarea {
      margin-top: 0!important;
      resize: vertical!important;
      font-size: 12px!important;
      letter-spacing: 0!important;
    }
  }
  .form-answers {
    @media screen and (min-width: 768px) {
      overflow-y: auto;
      min-width: 675px;
    }
    @media screen and (max-width: 768px) {
      text-align: center;
    }
    &::-webkit-scrollbar{
      width: 7px;
    }
    &::-webkit-scrollbar-thumb{
      width: 5px;
      border-radius: 8px;
      background-color: rgba(239,84,81,.75);
      box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    }
    &::-webkit-scrollbar-track{
      border: 1px solid rgba(0,0,0,.1);
    }
  }
}