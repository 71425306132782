@worst-color1: #a8a8aa;
@best-color1: #4f4756;
@worst-color2: #E37373;
@best-color2: #8FCC96;

.legend {
  white-space: nowrap;
}

.best-worst-titles {
  display:flex;
  font-size: 14px;
  color:  #5E4F60;
  padding: 0 0 10px;
  span {
    display: inline-block;
    &:before {
      content: "";
      display: inline-block;
      width: 30px;
      height: 15px;
      margin-right: 4px;
      position: relative;
      top: 3px;
    }
    &:first-child:before {
      background-image: linear-gradient(90deg, @worst-color1 50%, @worst-color2 50%);
    }
    &:last-child {
      margin-left: 15px;
      &:before {
        background-image: linear-gradient(90deg, @best-color1 50%, @best-color2 50%);
      }
    }
  }
}
.bestWord-container{
 &.best-worst-table {
   .best-worst-answer-row {
     position: relative;
     margin: 5px;
     max-width: 700px;
     padding: 10px 80px 0 0;
     .buttons {
       position: absolute;
       top: 0;
       bottom: 0;
       right: 0;
       font-size: 0;
       span {
         position: relative;
         display: inline-block;
         height: 100%;
         width: 38px;
         cursor: pointer;
         [class^=img] {
           position: absolute;
           width: 100%;
           height: 100%;
           background-size: auto 17px;
           background-repeat: no-repeat;
           background-position: center;
           &.img-empty {
             opacity: .6;
             transition: opacity 1s;
           }
           &.img-filled {
             visibility: hidden;
           }
         }
         input,label {
           display: none;
         }
         &.worst {
           background-color: @worst-color1;
           .background {
             right: 0;
             background-color: @worst-color2;
           }
           .img-empty {
             background-image: url("/images/new_respo/quiz/questions/white-cross-empty.png");
           }
           .img-filled {
             background-image: url("/images/new_respo/quiz/questions/white-cross-filled.png");
           }
         }
         &.best {
           background-color: @best-color1;
           .background {
             background-color: @best-color2;
           }
           .img-empty {
             background-image: url("/images/new_respo/quiz/questions/white-check-empty.png");
           }
           .img-filled {
             background-image: url("/images/new_respo/quiz/questions/white-check-filled.png");
           }
         }
         .background {
           position: absolute;
           width: 0;
           height: 100%;
           transition: width 0.5s;
         }
         &.disabled {
           pointer-events: none;
           .background {
             width: 0;
           }
           .img-empty {
             opacity: 0.3;
           }
         }
         &:hover {
           .img-empty {
             opacity: 1;
           }
         }
         &.selected {
           .background {
             width: 100%;
           }
           &.selected {
             [class^=img] {
               background-size: auto 15px;
             }
             .img-empty {
               visibility: hidden;
             }
             .img-filled {
               visibility: visible;
             }
           }
         }
       }
     }
   }
 }
}