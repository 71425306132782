
.BulkRadio-container{
  .border-bulk-table {
    .legend {
      white-space: nowrap;
    }
    .answer-text .not-numeric {
      white-space: nowrap;
      padding: 0 5px;
    }
  }
  .scale-hint{
    position: absolute;
    background-color: rgba(35, 31, 32, 1);
    color: #FFF;
    font: 12px "Myriad Pro LightExt";
    border: none;
    border-radius:2px;
    padding: 5px;
    left: -9999px;
    z-index: 999;
  }
  body .site-content .form-answers .wrapper{
    border: 0;
  }
}

body .site-content .form-answers .border-bulk-table.new-flex-bulk .answer-row .text-row.white label.col-1{
  width: 10%!important;
}
body .site-content .form-answers .border-bulk-table.new-flex-bulk .answer-row .text-row.white label.col-2{
  min-width: 20%!important;
}
body .site-content .form-answers .border-bulk-table.new-flex-bulk .answer-row .text-row.white label.col-3{
  min-width: 30%!important;
}
body .site-content .form-answers .border-bulk-table.new-flex-bulk .answer-row .text-row.white label.col-4{
  min-width: 40%!important;
}
body .site-content .form-answers .border-bulk-table.new-flex-bulk .answer-row .text-row.white label.col-5{
  min-width: 50%!important;
}
body .site-content .form-answers .border-bulk-table.new-flex-bulk .answer-row .text-row.white label.col-6{
  min-width: 60%!important;
}
body .site-content .form-answers .border-bulk-table.new-flex-bulk .answer-row .text-row.white label.col-7{
  min-width: 70%!important;
}
body .site-content .form-answers .border-bulk-table.new-flex-bulk .answer-row .text-row.white label.col-8{
  min-width: 80%!important;
}
body .site-content .form-answers .border-bulk-table.new-flex-bulk .answer-row .text-row.white label.col-9{
  min-width: 90%!important;
}
