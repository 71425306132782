body {
  background-color: #eaeaea;
}
@media (max-width: 767px), (max-height: 767px) {
  body {
    overflow: visible;
  }
}
@media (min-width: 768px), (min-height: 768px) {
  body {
    height: 100%;
  }
}
body .site-content {
  background: none;
  position: relative;
  min-width: 454px;
}
@media (min-width: 768px) {
  body .site-content {
    overflow: hidden;
    height: 100%;
    width: 100%;
  }
}
html {
  padding: 0;
}
@media (min-width: 768px), (min-height: 768px) {
  html {
    height: 100%;
  }
}
.new-test:not(.tester) {
  height: 100%;
  position: relative;
}
@media (min-width: 768px) {
  .new-test:not(.tester) {
    padding: 20px 0;
  }
}
.new-test:not(.tester).iframe {
  min-height: 450px;
}
@media screen and (max-height: 750px) {
  .new-test:not(.tester).iframe {
    padding-top: 89px;
  }
}
.new-test:not(.tester).iframe .cover .main-test-block {
  transition-property: none;
  box-shadow: none;
}
.new-test:not(.tester).iframe .cover .main-test-block .session-delay-btn {
  display: none;
}
.btn-next-block {
  width: 100%;
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.btn-next-block-small {
  margin: auto;
}
.btn-next-block-small .button-next,
.btn-next-block-small .button-stat {
  border-radius: 3px;
  margin: 5px;
  display: inline-block;
  vertical-align: top;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}
.btn-next-block-small .button-next .loading-circle,
.btn-next-block-small .button-stat .loading-circle {
  margin-top: 10px;
}
.btn-next-block-small .button-next:hover,
.btn-next-block-small .button-stat:hover {
  opacity: 0.85;
}
.btn-next-block-small .button-next .show-stat,
.btn-next-block-small .button-stat .show-stat,
.btn-next-block-small .button-next .btn-next,
.btn-next-block-small .button-stat .btn-next {
  cursor: pointer;
  display: block;
}
.btn-next-block-small .button-next .show-stat .img,
.btn-next-block-small .button-stat .show-stat .img,
.btn-next-block-small .button-next .btn-next .img,
.btn-next-block-small .button-stat .btn-next .img {
  width: 45px;
  height: 45px;
  border-radius: 5px;
}
.btn-next-block-small .button-next {
  position: relative;
}
.btn-next-block-small .button-next .btn-next .img {
  background: url("/images/quiz-buttons/next.png") no-repeat 100%;
  background-size: 45px;
}
.btn-next-block-small .button-stat {
  background-color: #4f4756;
  position: relative;
}
.btn-next-block-small .button-stat .show-stat .img {
  background: url("/images/quiz-buttons/stat.png") no-repeat 100%;
  background-size: 45px;
}
.btn-next-block-big {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  margin: auto;
  align-items: flex-end;
}
@media (max-width: 1200px) {
  .btn-next-block-big {
    margin: 0;
    justify-content: center;
  }
}
@media (max-width: 767px) {
  .btn-next-block-big {
    margin-right: 30px;
    justify-content: center;
  }
}
.big .btn-next div,
.big .show-stat div {
  line-height: 29px;
  height: 100%;
}
.button-next.big,
.button-stat.big {
  height: 40px;
  width: 145px;
  min-width: 145px;
  font-size: 11px;
  text-align: center;
  line-height: 25px;
  display: inline-block;
  letter-spacing: 0.5px;
  position: relative;
  font-family: 'Open Sans Semibold', serif;
  margin: 0 5px;
}
.button-next.big .popup-quiz,
.button-stat.big .popup-quiz {
  left: -59px;
}
.button-next.big a,
.button-stat.big a {
  text-decoration: none !important;
  color: #302E32;
}
.button-next.big:hover.button-next.big,
.button-stat.big:hover.button-next.big {
  background-color: #80CAC3;
  border-color: transparent;
}
.button-next.big:hover.button-stat.big,
.button-stat.big:hover.button-stat.big {
  background-color: #E6E7E8;
}
.button-next.big {
  border: 4px solid #80CAC3;
}
.button-stat.big {
  transition: border 0.1s ease-in-out;
  border: 4px solid #E6E7E8;
}
.button-stat.big.animate {
  animation: animateStat 1s 1 ease-in-out alternate;
}
@keyframes animateStat {
  0% {
    border: 4px solid #E6E7E8;
    transform: translateY(0);
  }
  25% {
    transform: translateY(-10px);
  }
  50% {
    border: 4px solid #80CAC3;
  }
  75% {
    transform: translateY(10px);
  }
  100% {
    border: 4px solid #E6E7E8;
    transform: translateY(0);
  }
}
.for-research-policy {
  width: 25%;
  text-align: left;
  font-size: 11px;
  font-family: 'Open Sans Semibold', serif;
  margin-top: -12px;
  margin-right: -17px;
}
@media (max-width: 1200px) {
  .for-research-policy {
    line-height: 13px;
  }
}
@media (max-width: 767px) {
  .for-research-policy {
    text-align: center;
    min-width: 100%;
    padding-top: 20px;
    margin-top: -10px;
  }
}
@media (max-width: 635px) {
}
@media (max-width: 591px) {
}
@media (max-width: 520px) {
  .for-research-policy {
    line-height: 11px;
  }
}
.session-delay-help-container {
  width: 176px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
@media (max-width: 1200px) {
  .session-delay-help-container {
    margin-right: -20px;
  }
}
@media (max-width: 545px) {
  .session-delay-help-container {
    width: auto;
  }
}
@media (max-width: 767px) {
  .session-delay-help-container {
    width: auto;
  }
}
.question-help {
  position: relative;
  margin-right: 10px;
}
.question-help .button {
  position: relative;
  width: 24px;
  height: 24px;
  cursor: pointer;
  border-radius: 3px;
  background-size: contain;
  background: url("/images/new_respo/quiz/questions/help_ico.png") no-repeat center, #EE2B4E;
}
.question-help .button:hover {
  background: url("/images/new_respo/quiz/questions/help_ico.png") no-repeat center, rgba(238, 43, 78, 0.75);
}
.question-help .button::after {
  content: "";
  display: block;
}
.question-help .button--tooltip:hover::after {
  border-color: transparent transparent rgba(238, 43, 78, 0.75) transparent;
}
.question-help .button--tooltip::after {
  content: " ";
  display: block;
  visibility: visible;
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -10px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent #EE2B4E transparent;
}
.question-help .hint {
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  display: none;
  background-color: #FF8275;
  color: #fff;
  min-width: 200px;
  padding: 10px;
  border-radius: 2px;
  bottom: 100%;
  margin-bottom: 10px;
  left: 0;
  position: absolute;
  z-index: 1000;
}
.question-help .hint--visible {
  display: block;
}
.question-help .hint .header {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px 5px;
  margin-bottom: 3%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.question-help .hint .header .text {
  font-size: 0.85rem;
  font-weight: 700;
}
.question-help .hint .header .close--hint {
  width: 10px;
  height: 10px;
  cursor: pointer;
  background: url("/images/new_respo/quiz/questions/close_hint.png") no-repeat center;
}
.question-help .hint .hint--text {
  padding: 5px 5px 0;
  font-size: 12px;
  text-align: left;
  line-height: 19px;
}
.show-stat {
  z-index: 100;
  position: relative;
}
.cover {
  position: relative;
}
@media (min-width: 768px), (min-height: 768px) {
  .cover {
    height: 100%;
  }
}
@media (max-width: 767px) {
  .cover {
    overflow: visible;
  }
}
.cover .main-test-block {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  padding: 40px 50px 56px 50px;
  background: rgba(255, 252, 251, 0.93);
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.25);
  display: none;
}
@media (min-width: 768px), (min-height: 768px) {
  .cover .main-test-block {
    transition: height 0.7s ease-in 0s, top 0.7s ease-in 0s, left 0.7s cubic-bezier(0.55, 0.06, 0.68, 0.19) 0s;
    height: 100%;
    width: 100%;
    display: block;
  }
}
@media (max-width: 767px) {
  .cover .main-test-block {
    padding: 32px 15px 15px 15px !important;
  }
  .cover .main-test-block:last-child {
    display: block;
    position: relative;
  }
  .cover .main-test-block .btn-next-block {
    bottom: 5px;
    position: relative;
    height: auto;
    width: auto;
    margin: 0;
    background-color: transparent;
  }
  .cover .main-test-block .btn-next-block:before {
    display: none;
  }
}
@media screen and (max-width: 545px) {
  .cover .main-test-block .btn-next-block-big {
    display: none;
  }
}
@media screen and (min-width: 545px) {
  .cover .main-test-block .btn-next-block-small {
    display: none;
  }
}
.cover .main-test-block .titles {
  position: relative;
  z-index: 100;
}
.RadioGroup-container .test-checkbox-input {
  transition: background .2s;
}
.RadioGroup-container .test-checkbox-input:hover {
  background: rgba(0, 0, 0, 0.08);
}
.RadioGroup-container [type="radio"]:checked + .test-checkbox.radioButton .radio-btn .radio-btn-inside {
  background-color: #80CAC3 !important;
}
@media (max-width: 767px) {
  .RadioGroup-container {
    column-count: unset!important;
  }
}
.CheckGroup-container {
  /*  column-count: <?= $options["columnsCount"] ?? "unset"?>;*/
  height: 99%;
}
@media (max-width: 767px) {
  .CheckGroup-container {
    column-count: unset;
  }
}
.CheckGroup-container .check-group-div .custom-answer {
  display: block!important;
}
.BulkRadio-container .border-bulk-table .legend {
  white-space: nowrap;
}
.BulkRadio-container .border-bulk-table .answer-text .not-numeric {
  white-space: nowrap;
  padding: 0 5px;
}
.BulkRadio-container .scale-hint {
  position: absolute;
  background-color: #231f20;
  color: #FFF;
  font: 12px "Myriad Pro LightExt";
  border: none;
  border-radius: 2px;
  padding: 5px;
  left: -9999px;
  z-index: 999;
}
.BulkRadio-container body .site-content .form-answers .wrapper {
  border: 0;
}
body .site-content .form-answers .border-bulk-table.new-flex-bulk .answer-row .text-row.white label.col-1 {
  width: 10%!important;
}
body .site-content .form-answers .border-bulk-table.new-flex-bulk .answer-row .text-row.white label.col-2 {
  min-width: 20%!important;
}
body .site-content .form-answers .border-bulk-table.new-flex-bulk .answer-row .text-row.white label.col-3 {
  min-width: 30%!important;
}
body .site-content .form-answers .border-bulk-table.new-flex-bulk .answer-row .text-row.white label.col-4 {
  min-width: 40%!important;
}
body .site-content .form-answers .border-bulk-table.new-flex-bulk .answer-row .text-row.white label.col-5 {
  min-width: 50%!important;
}
body .site-content .form-answers .border-bulk-table.new-flex-bulk .answer-row .text-row.white label.col-6 {
  min-width: 60%!important;
}
body .site-content .form-answers .border-bulk-table.new-flex-bulk .answer-row .text-row.white label.col-7 {
  min-width: 70%!important;
}
body .site-content .form-answers .border-bulk-table.new-flex-bulk .answer-row .text-row.white label.col-8 {
  min-width: 80%!important;
}
body .site-content .form-answers .border-bulk-table.new-flex-bulk .answer-row .text-row.white label.col-9 {
  min-width: 90%!important;
}
.BulkRadioImaged-container .img-container.border {
  background-color: unset!important;
}
.BulkRadioImaged-container .bulk-question-item {
  width: 215px;
}
.CheckGroupImaged-container input[type='text'] {
  display: block;
}
.CheckGroupImaged-container .buttons {
  margin: 0;
}
.CheckGroupImaged-container .custom-div {
  width: 100% !important;
  height: 100%;
}
.CheckGroupImaged-container .label-custom {
  padding: 0 10px;
  width: 100%;
  margin: 5px;
}
.CheckGroupImaged-container .images-container .buttons {
  max-width: 45%;
  width: 45%!important;
}
.CheckGroupImaged-container .images-container .buttons.col-sm-12 {
  width: 100% !important;
  max-width: 100%;
}
.CheckGroupImaged-container .images-container .buttons.col-sm-11 {
  width: 91.66666667% !important;
  max-width: 100%;
}
.CheckGroupImaged-container .images-container .buttons.col-sm-10 {
  width: 83.33333333% !important;
  max-width: 100%;
}
.CheckGroupImaged-container .images-container .buttons.col-sm-9 {
  width: 75% !important;
  max-width: 100%;
}
.CheckGroupImaged-container .images-container .buttons.col-sm-8 {
  width: 66.66666667% !important;
  max-width: 100%;
}
.CheckGroupImaged-container .images-container .buttons.col-sm-7 {
  width: 58.33333333% !important;
  max-width: 100%;
}
.CheckGroupImaged-container .images-container .buttons.col-sm-6 {
  width: 50% !important;
  max-width: 100%;
}
.CheckGroupImaged-container .images-container .buttons.col-sm-5 {
  width: 41.66666667% !important;
}
.CheckGroupImaged-container .images-container .buttons.col-sm-4 {
  width: 33.33333333% !important;
}
.CheckGroupImaged-container .images-container .buttons.col-sm-3 {
  width: 25% !important;
}
.CheckGroupImaged-container .images-container .buttons.col-sm-2 {
  width: 16.66666667% !important;
}
.CheckGroupImaged-container .images-container .buttons.col-sm-1 {
  width: 8.33333333% !important;
}
.CheckGroupImaged-container .buttons-custom .form-test-checkbox-input {
  margin-right: 0;
}
.CheckGroupImaged-container .buttons-custom .form-test-checkbox-input .test-checkbox .radioButton {
  padding-left: 0;
}
.CheckGroupImaged-container .buttons-custom .form-test-checkbox-input .test-checkbox .form-test-checkbox {
  padding: 0;
}
.CheckGroupImaged-container .buttons-custom .form-test-checkbox-input .test-checkbox .form-test-checkbox div {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}
.CheckGroupImaged-container input[type="text"] {
  background-color: transparent;
  font-family: "Open Sans", sans-serif;
  line-height: inherit;
  font-size: 12px;
}
.CheckGroupImaged-container input[type="text"]::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #7f7e7d;
}
.CheckGroupImaged-container input[type="text"]::-moz-placeholder {
  /* Firefox 19+ */
  color: #7f7e7d;
}
.CheckGroupImaged-container input[type="text"]:-ms-input-placeholder {
  /* IE 10+ */
  color: #7f7e7d;
}
.CheckGroupImaged-container input[type="text"]:-moz-placeholder {
  /* Firefox 18- */
  color: #7f7e7d;
}
.CheckGroupImaged-container .label-custom {
  margin: 0;
  padding: 0;
}
.CheckGroupImaged-container .item.form-test-checkbox-input.disabled {
  opacity: .4;
}
body .site-content .form-answers .content-question-main-images .images-container.big-images .buttons div:first-child label {
  height: auto;
}
body .site-content .form-answers .buttons-custom input[type='text'] {
  width: 100%!important;
}
.images-container .buttons label {
  display: block;
}
body .site-content .form-answers .content-question-main-images .buttons .text-image {
  margin: 0;
}
body .site-content .form-answers .content-question-main-images .buttons .text-image:hover {
  border: 1px solid #ef5451;
}
body .site-content .form-answers .content-question-main-images .buttons.no-checked .text-image:hover {
  border-width: 1px;
}
body .site-content .form-answers .content-question-main-images .buttons .text-image .hover {
  border: 1px solid transparent;
}
body .site-content .form-answers .content-question-main-images .buttons .text-image:hover .hover {
  border-color: #ef5451;
}
body .site-content .form-answers .content-question-main-images .buttons .text-image:hover .icon {
  border-style: solid;
  border-color: #ef5451;
  border-width: 2px 1px 1px 2px;
}
body .site-content .form-answers .content-question-main-images .buttons > div:first-child {
  width: auto;
}
body .site-content .form-answers .content-question-main-images .buttons .text-image .img {
  background-color: transparent;
  max-height: 200px;
}
body .site-content .form-answers .form-test-checkbox-input .test-checkbox-input .test-custom-img,
body .site-content .form-answers .test-custom-img .form-test-checkbox-input .test-checkbox-input:hover .test-custom-img {
  background: none !important;
  padding-left: 0;
}
body .site-content .form-answers .content-question-main-images .buttons.checked .text-image {
  border: 1px solid #ef5451;
}
body .site-content .form-answers .content-question-main-images .buttons .text-image:hover .icon {
  background-position: -1px -1px;
}
.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0px !important;
}
@media (min-width: 768px) {
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12 {
    float: left;
  }
  .col-sm-12 {
    width: 100% !important;
  }
  .col-sm-11 {
    width: 91.66666667% !important;
  }
  .col-sm-10 {
    width: 83.33333333% !important;
  }
  .col-sm-9 {
    width: 75% !important;
  }
  .col-sm-8 {
    width: 66.66666667% !important;
  }
  .col-sm-7 {
    width: 58.33333333% !important;
  }
  .col-sm-6 {
    width: 50% !important;
  }
  .col-sm-5 {
    width: 41.66666667% !important;
  }
  .col-sm-4 {
    width: 33.33333333% !important;
  }
  .col-sm-3 {
    width: 25% !important;
  }
  .col-sm-2 {
    width: 16.66666667% !important;
  }
  .col-sm-1 {
    width: 8.33333333% !important;
  }
}
.legend {
  white-space: nowrap;
}
.best-worst-titles {
  display: flex;
  font-size: 14px;
  color: #5E4F60;
  padding: 0 0 10px;
}
.best-worst-titles span {
  display: inline-block;
}
.best-worst-titles span:before {
  content: "";
  display: inline-block;
  width: 30px;
  height: 15px;
  margin-right: 4px;
  position: relative;
  top: 3px;
}
.best-worst-titles span:first-child:before {
  background-image: linear-gradient(90deg, #a8a8aa 50%, #E37373 50%);
}
.best-worst-titles span:last-child {
  margin-left: 15px;
}
.best-worst-titles span:last-child:before {
  background-image: linear-gradient(90deg, #4f4756 50%, #8FCC96 50%);
}
.bestWord-container.best-worst-table .best-worst-answer-row {
  position: relative;
  margin: 5px;
  max-width: 700px;
  padding: 10px 80px 0 0;
}
.bestWord-container.best-worst-table .best-worst-answer-row .buttons {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  font-size: 0;
}
.bestWord-container.best-worst-table .best-worst-answer-row .buttons span {
  position: relative;
  display: inline-block;
  height: 100%;
  width: 38px;
  cursor: pointer;
}
.bestWord-container.best-worst-table .best-worst-answer-row .buttons span [class^=img] {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: auto 17px;
  background-repeat: no-repeat;
  background-position: center;
}
.bestWord-container.best-worst-table .best-worst-answer-row .buttons span [class^=img].img-empty {
  opacity: .6;
  transition: opacity 1s;
}
.bestWord-container.best-worst-table .best-worst-answer-row .buttons span [class^=img].img-filled {
  visibility: hidden;
}
.bestWord-container.best-worst-table .best-worst-answer-row .buttons span input,
.bestWord-container.best-worst-table .best-worst-answer-row .buttons span label {
  display: none;
}
.bestWord-container.best-worst-table .best-worst-answer-row .buttons span.worst {
  background-color: #a8a8aa;
}
.bestWord-container.best-worst-table .best-worst-answer-row .buttons span.worst .background {
  right: 0;
  background-color: #E37373;
}
.bestWord-container.best-worst-table .best-worst-answer-row .buttons span.worst .img-empty {
  background-image: url("/images/new_respo/quiz/questions/white-cross-empty.png");
}
.bestWord-container.best-worst-table .best-worst-answer-row .buttons span.worst .img-filled {
  background-image: url("/images/new_respo/quiz/questions/white-cross-filled.png");
}
.bestWord-container.best-worst-table .best-worst-answer-row .buttons span.best {
  background-color: #4f4756;
}
.bestWord-container.best-worst-table .best-worst-answer-row .buttons span.best .background {
  background-color: #8FCC96;
}
.bestWord-container.best-worst-table .best-worst-answer-row .buttons span.best .img-empty {
  background-image: url("/images/new_respo/quiz/questions/white-check-empty.png");
}
.bestWord-container.best-worst-table .best-worst-answer-row .buttons span.best .img-filled {
  background-image: url("/images/new_respo/quiz/questions/white-check-filled.png");
}
.bestWord-container.best-worst-table .best-worst-answer-row .buttons span .background {
  position: absolute;
  width: 0;
  height: 100%;
  transition: width 0.5s;
}
.bestWord-container.best-worst-table .best-worst-answer-row .buttons span.disabled {
  pointer-events: none;
}
.bestWord-container.best-worst-table .best-worst-answer-row .buttons span.disabled .background {
  width: 0;
}
.bestWord-container.best-worst-table .best-worst-answer-row .buttons span.disabled .img-empty {
  opacity: 0.3;
}
.bestWord-container.best-worst-table .best-worst-answer-row .buttons span:hover .img-empty {
  opacity: 1;
}
.bestWord-container.best-worst-table .best-worst-answer-row .buttons span.selected .background {
  width: 100%;
}
.bestWord-container.best-worst-table .best-worst-answer-row .buttons span.selected.selected [class^=img] {
  background-size: auto 15px;
}
.bestWord-container.best-worst-table .best-worst-answer-row .buttons span.selected.selected .img-empty {
  visibility: hidden;
}
.bestWord-container.best-worst-table .best-worst-answer-row .buttons span.selected.selected .img-filled {
  visibility: visible;
}
.radio-group-matrix-div .custom-input {
  display: block;
  width: 100%;
  color: #000;
  padding: 0 3px;
  border: 0;
  background-color: transparent!important;
  text-align: center;
}
.radio-group-matrix-div .custom-input::placeholder {
  color: rgba(0, 0, 0, 0.6);
  opacity: 1;
}
.radio-group-matrix-div .stub {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fffcfb;
  width: 100px;
  z-index: 101;
  border-bottom: 2px solid rgba(50, 0, 0, 0.25);
  border-right: 2px solid rgba(50, 0, 0, 0.25);
}
.radio-group-matrix-div th,
.radio-group-matrix-div td {
  min-width: 130px;
}
.radio-group-matrix-div .floating-column-headers {
  display: flex;
  position: absolute;
  align-items: stretch;
  top: 0;
  z-index: 10;
  left: 0;
  margin-bottom: 0;
  background: #fffcfb;
}
.radio-group-matrix-div .floating-column-headers.transposed {
  align-items: stretch;
  min-height: 35px;
}
.radio-group-matrix-div .floating-column-headers.transposed th,
.radio-group-matrix-div .floating-column-headers.transposed td {
  min-width: 130px;
}
.radio-group-matrix-div .floating-column-headers.transposed .header {
  min-height: 35px;
  border: 1px solid rgba(50, 0, 0, 0.25);
  border-top: none;
  border-bottom: 0;
}
.radio-group-matrix-div .floating-column-headers.transposed .header:nth-child(even) {
  background: rgba(0, 0, 0, 0.035);
}
.radio-group-matrix-div .floating-column-headers.transposed .header.img {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  min-height: 100%;
}
.radio-group-matrix-div .floating-column-headers.transposed .header.img img {
  max-width: 80px;
  max-height: 80px;
  display: inline-block;
  vertical-align: middle;
}
.radio-group-matrix-div .floating-column-headers.transposed .header.img p {
  min-width: 100%;
  align-self: flex-end;
  padding-bottom: 15px;
}
.radio-group-matrix-div .floating-column-headers.vertical-header {
  display: block;
  border-right: 2px solid rgba(50, 0, 0, 0.25);
}
.radio-group-matrix-div .floating-column-headers.vertical-header .header {
  background-color: #fffcfb;
  border: 0;
  border-top: 1px solid rgba(50, 0, 0, 0.25);
  border-bottom: 1px solid rgba(50, 0, 0, 0.25);
}
.radio-group-matrix-div .floating-column-headers.vertical-header .header:nth-child(even) {
  background: #fffcfb;
}
.radio-group-matrix-div .floating-column-headers.vertical-header .header:last-child {
  border-bottom-width: 2px;
}
.radio-group-matrix-div .floating-column-headers.vertical-header .empty-header {
  border: 0;
  left: 0;
  top: 0;
}
.radio-group-matrix-div .floating-column-headers.vertical-header .empty-header + .header {
  border-top-width: 2px;
}
.radio-group-matrix-div .floating-column-headers.horizontal-header {
  border-bottom: 2px solid rgba(50, 0, 0, 0.25);
}
.radio-group-matrix-div .floating-column-headers .header,
.radio-group-matrix-div .floating-column-headers .empty-header {
  border-bottom: 0;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.radio-group-matrix-div .floating-column-headers .header,
.radio-group-matrix-div .floating-column-headers .empty-header {
  width: 100px;
  text-align: center;
  padding: 0 5px;
}
.radio-group-matrix-div .floating-column-headers .header {
  border: 1px solid rgba(50, 0, 0, 0.25);
  border-top: none;
  border-bottom: 0;
}
.radio-group-matrix-div .floating-column-headers .header:last-child {
  border-right: none;
}
.radio-group-matrix-div .floating-column-headers .empty-header {
  border: 2px solid transparent;
  border-left: none;
  border-right: 1px solid rgba(50, 0, 0, 0.25);
}
.radio-group-matrix-div .floating-column-headers.fixed {
  position: fixed;
  z-index: 999;
  width: 100%;
  max-width: 1200px;
  top: 0;
}
.radio-group-matrix-div .radio-group-matrix {
  min-width: 100%;
}
.radio-group-matrix-div .radio-group-matrix label {
  width: 100%;
}
.radio-group-matrix-div .radio-group-matrix input:not(.custom-input) {
  display: none;
}
.radio-group-matrix-div .radio-group-matrix th {
  min-height: 55px;
  width: 100px;
  text-align: center;
  padding: 5px;
}
.radio-group-matrix-div .radio-group-matrix td,
.radio-group-matrix-div .radio-group-matrix th {
  border: 1px solid rgba(50, 0, 0, 0.25);
}
.radio-group-matrix-div .radio-group-matrix td:first-child,
.radio-group-matrix-div .radio-group-matrix th:first-child {
  border-left: none;
}
.radio-group-matrix-div .radio-group-matrix td:last-child,
.radio-group-matrix-div .radio-group-matrix th:last-child {
  border-right: none;
}
.radio-group-matrix-div .radio-group-matrix.transposed .radio-button:not(.disabled):nth-child(even),
.radio-group-matrix-div .radio-group-matrix.transposed .check-button:not(.disabled):nth-child(even) {
  background: rgba(0, 0, 0, 0.035);
}
.radio-group-matrix-div .radio-group-matrix.transposed th {
  padding-top: 10px;
  padding-bottom: 10px;
}
.radio-group-matrix-div .radio-group-matrix.transposed td,
.radio-group-matrix-div .radio-group-matrix.transposed th {
  border: 1px solid rgba(50, 0, 0, 0.25);
}
.radio-group-matrix-div .radio-group-matrix.transposed td:first-child,
.radio-group-matrix-div .radio-group-matrix.transposed th:first-child {
  border-left: none;
}
.radio-group-matrix-div .radio-group-matrix.transposed td:last-child,
.radio-group-matrix-div .radio-group-matrix.transposed th:last-child {
  border-right: none;
}
.radio-group-matrix-div .radio-group-matrix.transposed .item-row .item-text {
  padding-right: 5px;
}
.radio-group-matrix-div .radio-group-matrix.transposed .item-row:nth-child(even) {
  background: initial;
}
.radio-group-matrix-div .radio-group-matrix.transposed .item-row:last-child td,
.radio-group-matrix-div .radio-group-matrix.transposed .item-row:last-child th {
  border-bottom-width: 2px;
}
.radio-group-matrix-div .radio-group-matrix .item-row {
  height: 100px;
}
.radio-group-matrix-div .radio-group-matrix .item-row:nth-child(even) {
  background: rgba(0, 0, 0, 0.035);
}
.radio-group-matrix-div .radio-group-matrix .item-text {
  overflow-x: auto;
  padding: 10px 0 10px 10px;
  vertical-align: middle;
  text-align: center;
}
.radio-group-matrix-div .radio-group-matrix .item-text.with-img {
  overflow: hidden;
}
.radio-group-matrix-div .radio-group-matrix .item-text.with-img .img {
  max-height: 80px;
  max-width: 80px;
  display: inline-block;
}
.radio-group-matrix-div .radio-group-matrix .item-text.with-img p {
  text-align: center;
}
.radio-group-matrix-div .radio-group-matrix .radio-button,
.radio-group-matrix-div .radio-group-matrix .check-button {
  text-align: center;
  vertical-align: middle;
}
.radio-group-matrix-div .radio-group-matrix .custom-button {
  transition: none;
}
.radio-group-matrix-div .radio-group-matrix .custom-button.disabled {
  background-color: #e5e5e5;
  pointer-events: none;
}
.radio-group-matrix-div .radio-group-matrix .check-button.disabled {
  pointer-events: none;
  background-color: #e5e5e5;
}
.radio-group-matrix-div .radio-group-matrix .radio-matrix-check-button {
  background: url("/images/new_respo/quiz/questions/check11-text.png") no-repeat 50% 50%;
  overflow: hidden;
  transition: background 0.2s ease-out 0s, color 0.2s ease-out 0s, opacity 0.5s ease-out 0s, filter 0.2s linear;
  width: 100%;
  height: 70px;
}
.radio-group-matrix-div .radio-group-matrix .radio-matrix-check-button:not(.active):hover {
  background: url("/images/new_respo/quiz/questions/check11-text.png") no-repeat 50% 50%, rgba(0, 0, 0, 0.08);
  cursor: pointer;
}
.radio-group-matrix-div .radio-group-matrix .radio-matrix-check-button.active:hover {
  background: url("/images/new_respo/quiz/questions/check1-text-active.png") no-repeat 50% 50%, rgba(0, 0, 0, 0.08);
  cursor: pointer;
}
.radio-group-matrix-div .radio-group-matrix .radio-matrix-check-button.active {
  background-image: url("/images/new_respo/quiz/questions/check1-text-active.png");
  filter: saturate(230%) contrast(195%) brightness(65%);
}
.radio-group-matrix-div .radio-group-matrix .radio-button .radio-matrix-button-wrapper {
  transition: background 0.2s;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
}
.radio-group-matrix-div .radio-group-matrix .radio-button .radio-matrix-button-wrapper:hover .radio-matrix-button {
  border: 2px solid #7a6652;
  cursor: pointer;
}
.radio-group-matrix-div .radio-group-matrix .radio-button .radio-matrix-button-wrapper:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.08);
}
.radio-group-matrix-div .radio-group-matrix .radio-button .radio-matrix-button-wrapper .radio-matrix-button {
  margin: 0 auto;
  width: 25px;
  position: relative;
  height: 25px;
  display: block;
  border: 2px solid rgba(122, 102, 82, 0.5);
  border-radius: 50%;
  z-index: 5;
  transition: border linear .2s 0s, filter .2s linear;
}
.radio-group-matrix-div .radio-group-matrix .radio-button .radio-matrix-button-wrapper .radio-matrix-button .inside-circle {
  position: absolute;
  left: 50%;
  top: 50%;
  border-radius: 50%;
  display: none;
  margin-left: -7.5px;
  margin-top: -7.5px;
  width: 15px;
  height: 15px;
}
@media screen and (max-width: 600px) {
  .radio-group-matrix-div .radio-group-matrix .radio-button .radio-matrix-button-wrapper .radio-matrix-button .inside-circle {
    width: 10px;
    height: 10px;
    margin-left: -5px;
    margin-top: -5px;
  }
}
.radio-group-matrix-div .radio-group-matrix .radio-button .radio-matrix-button-wrapper .radio-matrix-button.active {
  border: 2px solid #7a6652;
}
.radio-group-matrix-div .radio-group-matrix .radio-button .radio-matrix-button-wrapper .radio-matrix-button.active > .inside-circle {
  display: block;
  transition: background linear 0.2s 0s;
  background: #7a6652;
  opacity: .8;
}
.radio-group-matrix-div .radio-group-matrix .radio-button .radio-matrix-button-wrapper .radio-matrix-button:not(.active) > .inside-circle {
  display: none;
}
@media screen and (max-width: 600px) {
  .radio-group-matrix-div .radio-group-matrix .radio-button .radio-matrix-button-wrapper .radio-matrix-button {
    width: 20px;
    height: 20px;
  }
}
.SelectCity-container .ui-autocomplete {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  font-family: 'Open Sans', serif;
}
.SelectCity-container .ui-autocomplete .ui-menu-item {
  padding: 10px 1em 10px .4em;
}
.SelectCity-container .ui-autocomplete .ui-state-focus {
  color: black;
  outline: none;
  border: none;
  font-family: 'Open Sans Semibold', serif;
  background: #80CAC3;
}
.bulkInputText-container .header-text {
  font: normal 18px "Open Sans", serif;
  letter-spacing: 1px;
  color: #000;
  text-transform: none;
  max-width: 722px;
  padding: 0 30px 10px 0;
  margin: 0 0 20px;
}
.bulkInputText-container .bulk-text-column {
  display: inline-block;
  margin: 10px;
}
.bulkInputText-container .bulk-text-column > p {
  color: rgba(0, 0, 0, 0.75);
  font: 14px "Open Sans";
  text-transform: none;
  margin-bottom: 10px;
}
.bulkInputText-container .bulk-text-column > textarea {
  margin-top: 0!important;
  resize: vertical!important;
  font-size: 12px!important;
  letter-spacing: 0!important;
}
@media screen and (min-width: 768px) {
  .bulkInputText-container .form-answers {
    overflow-y: auto;
    min-width: 675px;
  }
}
@media screen and (max-width: 768px) {
  .bulkInputText-container .form-answers {
    text-align: center;
  }
}
.bulkInputText-container .form-answers::-webkit-scrollbar {
  width: 7px;
}
.bulkInputText-container .form-answers::-webkit-scrollbar-thumb {
  width: 5px;
  border-radius: 8px;
  background-color: rgba(239, 84, 81, 0.75);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.bulkInputText-container .form-answers::-webkit-scrollbar-track {
  border: 1px solid rgba(0, 0, 0, 0.1);
}
